import React from 'react';
import logo from "../images/logo.svg";
import moscow from "../images/moscow.svg";

export default function Profile({
                                  currentUser,
                                  onEditAvatar,
                                  onAddPlace,
                                }) {
  return (
    <section className='profile page__container'>
        <p className='profile__text'>Я живу в городе-герое Москва! В самом центре стоит
            Кремль - древняя крепость с высокими башнями, где живут президенты. Рядом с
            Кремлем находится Красная площадь и храм Василия Блаженного, похожий на
            сказочный пряничный домик! В Москве есть ещё много интересных
            мест. Например, зоопарк, где живут животные со всего мира: львы, слоны, обезьяны! А ещё в Москве течёт река
            Москва, по которой можно кататься на корабликах и любоваться красивыми видами.</p>
      <div className='profile__wrapper'>
        <div className='profile__name-edit'>
          <img className='header__logo' src={logo} alt='My City'/>
          <img className='header__moscow' src={moscow} alt='Moscow'/>
        </div>
      </div>

      {/*<button*/}
      {/*  className='profile__button-add button'*/}
      {/*  type='button'*/}
      {/*  aria-label='Добавить место'*/}
      {/*  onClick={onAddPlace}*/}
      {/*></button>*/}
    </section>
  );
}
